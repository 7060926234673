@use '@angular/material' as mat;

/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables

//PALETTE
$mat-primary: (
  50: #e6e6e6,
  100: #c0c0c0,
  200: #979797,
  300: #6d6d6d,
  400: #4d4d4d,
  500: #2e2e2e,
  600: #292929,
  700: #232323,
  800: #1d1d1d,
  900: #121212,

  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,

  )
);

$mat-secondary: (
  50: #e6e6e6,
  100: #c0c0c0,
  200: #979797,
  300: #6d6d6d,
  400: #4d4d4d,
  500: #2e2e2e,
  600: #292929,
  700: #232323,
  800: #1d1d1d,
  900: #121212,

  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
  )
);

$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;

$primary: mat.get-color-from-palette($mat-primary, 500);
$secondary: mat.get-color-from-palette($mat-secondary, 900);

$body-color: #eef5f9;
$heading-text: #455a64;
$body-text: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;


$theme-color: #1e88e5;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 4px;
